import React, { Component } from 'react'
import './index.css'

class YiHaoService extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = '合作服务协议'
  }

  render() {

    return (
      <div className='yihao-service-body'>
        <p>本《合作服务协议》（以下称“本协议”）为杭州易号链接网络科技有限公司（以下称“合作公司”）、本平台与您签订。您通过页面勾选或点击确认或以其他方式选择接受本协议，即表示您与合作公司、本平台达成协议并同意接受本协议的全部约定内容。如果您不同意本协议的任意内容，或者无法准确理解本协议条款，请您停止使用本服务。</p>
        <p>在接受本协议之前，请您仔细阅读本协议的全部内容，以及与您权益具有或者有可能具有重大关系的条款，及对合作公司及/或本平台具有免责或限制责任的条款。</p>
        <p>一、服务内容<br />您同意并确认，作为合作公司的签约服务方，接受合作公司/本平台利用自身专业优势，运营、管理您至本平台从事各项活动（包括但不限于直播活动、视频创作等，以下称“活动”），具体以您在本平台实际开展的活动为准。</p>
        <p>二、服务期限<br />
          <div>1、本协议有效期自您接受本协议之日起至您停止在本平台开展活动之日止。</div>
          <div>2、合作公司及/或本平台有权因政策变化、双方交易变化、本平台政策变更等原因，提前终止本协议，除按照本协议条款与您结算本协议解除时尚未支付的费用外，合作公司及/或本平台均无需承担任何其他义务或责任。</div>
        </p>
        <p>三、各方权利义务<br />
          <div>1、您勾选或点击确认本协议，即视为您为合作公司的签约服务方，合作公司有权选派您及/或处理您在本平台开展活动、提供相应运营服务，并与本平台业务接洽相关事宜。</div>
          <div>2、合作公司有权根据本协议约定对您的行为实施监督、管理。</div>
          <div>3、本协议生效后，您仍应当继续履行您此前与本平台签署的所有线上或线下协议（除非该等协议条款已被本协议明确变更），并同意遵守本平台根据运营情况不定时制定、调整与服务相关的服务政策、规范、制度等。前述协议、政策、规范、制度等统称“平台规则”。</div>
          <div>4、您的服务、言词、行为以及上传的图文、视频、直播等内容等都应遵守法律法规及本平台规则（包括但不限于用户协议、直播主播入驻协议等），否则合作公司及/或本平台均有权终止本协议，暂停向您支付本协议项下待支付款项，并要求您承担一切损失。</div>
          <div>5、您有义务维护合作公司和本平台的声誉与合法权益，您承诺不作任何有损合作公司及本平台声誉或合法权益的行为，否则合作公司及/或本平台均有权终止本协议，暂停向您支付本协议项下待支付款项，并要求您承担一切损失。</div>
          <div>6、 您同意并确认，授权合作公司依据相关法规，就您在本协议下所获得的收入代征个人所得税、增值税及附加等相关税费，并为您办理税务相关事宜，包括但不限于办理代开发票、办理税款缴纳等，并授权合作公司制作您办理税务事宜所需相关程序性文件。</div>
          <div>7、您同意授权合作公司及本平台有权因本协议项下收入结算需要获取您的身份证、照片、手机号、电子邮箱、结算及报税相关信息，以及其他相关授权信息。若您提供任何错误、虚假、失效或不完整的资料，或者合作公司有合理的理由怀疑资料为错误、虚假、过期或不完整，合作公司有权暂停或终止向您结算服务费，对此合作公司不承担任何责任，您承诺并同意负担因此所产生的所有损失，包括但不限于直接损失、间接损失。若因国家法律法规、部门规章或监管机构的要求，合作公司需要您补充提供任何相关资料时，如您不能及时配合提供，本平台及/或合作公司有权暂停或终止结算。</div>
          <div>8、您同意，为配合税务等监管部门调查等目的，本平台及/或合作公司均有权在所被要求的范围内，向相关监管部门提供与您在本平台收入及报税的相关信息，为此我们无需另行获得您的同意或授权。</div>
          <div>9、如您违反本协议或平台规则中的任何条款，则本平台及/或合作公司有权停止向您支付本协议项下费用而不构成违约；如您违反上述条款的行为导致合作公司遭受损失的，合作公司有权要求您赔偿。同时，本条款不影响本合作平台就您违反合作平台规则的行为追究您的责任。</div>
          <div>10、您确认，因本平台业务多样性，如您的某笔收入并非由合作公司直接支付，则就该笔收入不适用本协议，您不应再根据本协议要求合作公司及/或本平台重复向您支付该笔费用。关于该笔收入的相关事项，您应适用与向您支付费用的主体的相关协议。</div>
          <div>11、未经其他方书面同意，您不得将其本协议项下的权利义务转让/转包/分包/委托予任何第三方。为免疑义，本平台及/或合作公司有权在事先以平台公告或站内信通知您前提下，将本协议项下的权利义务转让/转包/分包/委托给合作公司的关联公司或本平台指定的其他公司，该行为自相应通知送达之日起生效。</div>
        </p>
        <p>四、费用及支付<br />
          <div>1、您在本平台开展相关活动有权获得收入，合作公司将根据本平台后台数据核算您的收入金额；同时，合作公司应将对应金额支付至您的收款账户，各方确认，合作公司向收款账户支付对应收入后，即视为已履行完毕对您应承担的全部付款义务，您不应再要求本平台及/或合作公司支付任何费用。</div>
          <div>您同意并确认，您就您在本平台开展相关活动所获得的收入自行承担个人所得税、增值税及附加税费等税费成本。与此同时，您授权合作公司在向上述收款账户支付对应费用或将对应收入兑换为虚拟币前扣除相应税款和费用（如涉及）并根据相关法律法规进行相应税款的申报与缴纳。</div>
          <div>前述"收款账户"是指您在本平台预留的以您名义开设的银行账户或您与本平台另行共同确认的其他账户。</div>
          <div>2、您确认，您充分理解本协议项下费用结算关系仅在您和合作公司之间发生，与本平台无关，您在任何情况下不应直接要求本平台向您结算任何费用。</div>
        </p>
        <p>五、保密责任<br />
          <div>1、您应严格遵守合作公司及本平台的保密制度，承诺无限期保守合作公司和本平台的商业秘密。因您违反约定使用或披露商业秘密和信息使合作公司、本平台遭受任何名誉、声誉或经济上的、直接或间接的损失，您应全额赔偿。</div>
          <div>商业秘密是指由合作公司及/或本平台提供的、或者您在双方合作期间了解到的、或者合作公司及/或本平台对第三方承担保密义务的，与合作公司及本平台业务有关的，能为其带来经济利益，具有实用性的、非公知的所有信息，包括但不限于：技术信息、经营信息和与合作公司及合作平台行政管理有关的信息和文件（含本协议及相关协议内容）、您从本平台及合作公司获得的服务费用的金额和结算方式、标准、权利归属方式、授权方式、客户名单的名单、联系方式、服务费用、工作人员名单等不为公众所知悉的信息，本协议安排等。</div>
          <div>2、您应严格遵守本协议，未经公司书面授权或同意，对公司的商业秘密不得：</div>
          <div>（1）以任何方式向第三方或不特定的公众进行传播、泄露；</div>
          <div>（2）为非本协议的目的而使用公司的商业秘密。</div>
          <div>3、如果本协议因任何原因终止或不再履行，您不得将任何有关公司内容的信息披露或透露给任何本协议之外的其他方。</div>
          <div>4、以上保密条款并不因本协议的解除、终止、无效而失效。</div>
        </p>
        <p>六、免责条款<br />
          <div>您同意，在下列情形下合作公司及本平台无需承担任何责任：</div>
          <div>1、任何由于黑客攻击、计算机病毒侵入或发作、电信部门技术调整导致之影响、因政府管制而造成的暂时性关闭、由于第三方原因（包括不可抗力，例如国际出口的主干线路及国际出口电信提供商一方出现故障、火灾、水灾、雷击、地震、洪水、台风、龙卷风、火山爆发、瘟疫和传染病流行、罢工、战争或暴力行为或类似事件等）及其他非因合作公司及/或本平台过错而造成的费用结算失败等。</div>
          <div>2、您同意，您向合作公司及/或本平台提供错误、不完整、不实信息等造成费用结算失败或遭受任何其他损失，应由您自行承担。</div>
        </p>
        <p>七、其他<br />
          <div>1、因本协议的订立和履行，本平台需要向您发出的任何通知、指示、催告、文件扫描件或电子文档或者其他任何形式的意思表示，均可通过包括但不限于电子邮件、站内信、系统通知或页面公告等形式中的一种或几种发送或接收。</div>
          <div>2、本协议的订立、效力、解释、履行和争议解决均适用中华人民共和国法律。</div>
          <div>3、基于本协议的任何争议，您应与合作公司、本平台友好协商解决；协商不成，应提交合同签订地即北京市朝阳区的人民法院处理。</div>
        </p>
      </div>
    );
  }
}

export default YiHaoService;
