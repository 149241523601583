import React, { Component } from 'react'
import './index.css'
import axios from 'axios'

class FastSelling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceQrCode: null,
    }
  }

  componentDidMount() {

    axios({
      method: 'get',
      url: '/miniapp-api/v1/banner/img-list?show_type=6',
    }).then((res) => {
      this.setState({
        serviceQrCode: res.data.data[0].img_link
      })
    }).catch((error) => {
      console.log(error)
    })

  }

  render() {

    const { serviceQrCode } = this.state;

    return (
      <div className='fast-selling-body'>
        <div className='title-box'>
          <div>新手达人如何快速卖货？</div>
        </div>
        <img className='bg' alt='fast selling bg' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/join_group_bg.png'></img>

        <div className='des'>达人您好，在易通告-选品大厅看到心仪的商品，如何在抖音平台售卖赚取佣金？</div>

        <div className='tips-box'>
          <div className='tips'>第一步：成为精选联盟达人，开通橱窗；</div>
          <div className='adorn'></div>
        </div>

        <div className='content'>
          开通橱窗有如下3个要求：
          <div>1.保证金500元，后期如果不想带货可以退回；</div>
          <div>2.个人发布10条以上视频，如果不够，可以自行拍摄发布；</div>
          <div>3.粉丝数够1000人，加油更新作品哦。</div>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/fast_selling_step1.png'></img>
          拥有橱窗权限后就可以为橱窗添加商品了！
        </div>

        <div className='tips-box'>
          <div className='tips'>第二步：在易通告选择合适商品，点击复制链接；</div>
          <div className='adorn'></div>
        </div>

        <div className='content'>
          打开抖音APP-我-商品橱窗-选品广场-链接，粘贴链接后点击“加橱窗”，卖出后就可以获得返佣哦~
          <p>1.复制易通告链接</p>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/fast_selling_step2_1.png'></img>
          <p>2.打开抖音APP-我-商品橱窗</p>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/fast_selling_step2_2.png'></img>
          <p>3.点击右上角“链接”</p>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/fast_selling_step2_3.png'></img>
          <p>4.粘贴链接并加橱窗</p>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/fast_selling_step2_4.png'></img>
        </div>


        <div className='tips-box'>
          <div className='tips'>第三步：拍视频/直播时都可以进行商品讲解，卖出后精选联盟自动结算佣金哦~</div>
          <div className='adorn'></div>
        </div>

        <div className='qrcode-tip'>
          欢迎加入达人带货群
        </div>
        <div className='qrcode-box'>
          <img className='qrcode' alt='service' src={`${serviceQrCode}?t=` + new Date()}></img>
        </div>
      </div>
    );
  }
}

export default FastSelling;
