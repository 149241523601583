import './App.css';
import JoinGroup from './pages/JoinGroup'
import FastSelling from './pages/FastSelling'
import ShopGuide from './pages/ShopGuide'
import TaxRules from './pages/TaxRules'
import YiHaoService from './pages/YiHaoService'
import DoukeIncomeRule from './pages/DoukeIncomeRule'
import DoukeShare from './pages/DoukeShare'
import Login from './Login'
import { Switch, Route } from 'react-router'


function App() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/join-group" component={JoinGroup} />
      <Route path="/fast-selling" component={FastSelling} />
      <Route path="/shop-guide" component={ShopGuide} />
      <Route path="/tax-rules" component={TaxRules} />
      <Route path="/yihao-service" component={YiHaoService} />
      <Route path="/douke-income-rule" component={DoukeIncomeRule} />
      <Route path="/douke-share" component={DoukeShare} />
    </Switch>
  );
}

export default App;
