import React, { Component } from 'react'
import './index.css'

class TaxRules extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = '代征个人所得税规则'
  }

  render() {

    return (
      <div className='tax-rules-body'>
        <div className='tax-rules-title'>个人劳务报酬所得税计算方法是什么？</div>
        <div>劳务报酬个人所得税计算方法如下：</div>
        <div>1、劳务报酬收入不足4000元的，用收入减去800元的费用;每次劳务报酬收入超过4000元的，用收入减去收入额的20%，税率为20%。</div>
        <div>例如：A人员当月在易通告提现1800元，要交(1800-800)×20% = 200 元个税；按提取月份申报计算个税。</div>
        <div>2、劳务报酬收入超过4000元的，用收入减去收入额的20%，税率为20%。单次应纳税所得额不超过2万的：应纳税额=应纳税所得额×20%。</div>
        <div>例如：B人员当月在易通告提现5000元，则要交5000×(1-20%)×20% = 800 元个税。</div>
        <div>3、我国税法对于取得劳务报酬畸高的实行加成征收。这里所指取得劳务报酬畸高是单次应纳税所得额超过2万元的情况。</div>
        <div>单次应纳税所得额超过2万元的，不超过5万元的，加成至税率30%：应纳税所得额×30%-2000。单次应纳税所得额超过5万的，税率加成至40%：应纳税额=应纳税所得额×40%-7000。</div>
        <div>4、单一缴税个人为实名认证个人，按照自然月提现金额计算税款。</div>
      </div>
    );
  }
}

export default TaxRules;
