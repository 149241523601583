import React, { Component } from 'react'
import './index.css'
import axios from 'axios'

class JoinGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceQrCode: null,
    }
  }

  componentDidMount() {

    axios({
      method: 'get',
      url: '/miniapp-api/v1/banner/img-list?show_type=6',
    }).then((res) => {
      this.setState({
        serviceQrCode: res.data.data[0].img_link
      })
    }).catch((error) => {
      console.log(error)
    })

  }

  render() {

    const { serviceQrCode } = this.state;

    return (
      <div className='shop-guide-body'>
        <div className='title-box'>
          <div>新手达人如何找开单神器？</div>
        </div>
        <img className='bg' alt='fast selling bg' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/join_group_bg.png'></img>

        <div className='des'>达人您好，易通告-选品大厅商品数量较多，如果您有心仪的品可以通过搜索找到；如果您还不知道该如何带货，可以试着从商品金额低于9.9元的品开始，粉丝购买率会更高一些。</div>

        <div className='tips-box'>
          <div className='tips'>第一步：点击筛选，在最高价输入10，就可以搜到10元内的商品了；</div>
          <div className='adorn'></div>
        </div>

        <div className='content'>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/shop_guide_step1.png'></img>
        </div>

        <div className='tips-box'>
          <div className='tips'>第二步：筛选销量更好的品，更容易出单。</div>
          <div className='adorn'></div>
        </div>

        <div className='content'>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/shop_guide_step2.png'></img>
        </div>

        <div className='qrcode-tip'>
          欢迎加入达人带货群
        </div>
        <div className='qrcode-box'>
          <img className='qrcode' alt='service' src={`${serviceQrCode}?t=` + new Date()}></img>
        </div>
      </div>
    );
  }
}

export default JoinGroup;
