import React, { Component } from 'react'
import './index.css'

class DoukeIncomeRule extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = '收益规则'
  }

  render() {

    return (
      <div className='douke-income-rule-body'>
        <p>易通告平台收益明细构成</p>
        <p>在易通告平台内进行分享赚钱有3种收益方式：</p>
        <p>1.商品收益；<br />在选品大厅，找到需要分享的商品，通过分享抖口令/抖音专属码到社交媒体平台，例如：微信/QQ/微博等，只要有用户通过您的分享购买商品，您就可以获得商品收益；</p>
        <p>2.团队收益；<br />通过我的-分享赚-邀请好友，组织自己的团队，那么团队成员在选品大厅通过分享抖口令/抖音专属码到社交媒体平台产生的成交，易通告平台会额外再奖励一部分收益给到您个人，团队人数越多，团队收益越多；</p>
        <p>3.直播间收益；<br />进入抖音直播间页面，平台会展示正在直播推广的直播间，通过分享抖口令/抖音专属码到社交媒体平台，例如：微信/QQ/微博等，只要有用户通过您的分享购买直播间中的商品，您就可以获得商品收益；</p>
        <p>4.预估收益：<br />订单中列出的预估收益是下单后系统自动计算出的收益值，实际已最终结算为准，中间如订单产生退货/取消，那么该收益无法进入结算环节；</p>
        <p>如有任何其他收益问题，可以直接联系平台客服。</p>
      </div>
    );
  }
}

export default DoukeIncomeRule;
