import React, { Component } from 'react'
import './index.css'
import axios from 'axios'

class DoukeShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className='douke-share-body'>
        <div className='title-box'>
          <div>分享也能赚钱了~</div>
        </div>
        <img className='bg' alt='fast selling bg' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/join_group_bg.png'></img>

        <div className='des'>抖音商城海量商品，快来分享赚！2023年新的赚钱方法，大家都要爆单单~</div>

        <div className='tips-box'>
          <div className='tips'>方法一</div>
          <div className='adorn'></div>
        </div>

        <div className='content'>
          <div>选中要分享的商品，点击“分享赚”，可以通过平台抖口令/素材分享到微信群中，用户购买后即可赚钱。</div>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/douke_share1_1.png'></img>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/douke_share1_2.png'></img>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/douke_share1_3.png'></img>
        </div>

        <div className='tips-box'>
          <div className='tips'>方法二</div>
          <div className='adorn'></div>
        </div>

        <div className='content'>
          <div>直播间分享，选择热门直播间，点击“分享赚”，可以通过平台抖口令/素材分享到微信群中，用户购买后即可赚钱。</div>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/douke_share2_1.png'></img>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/douke_share2_2.png'></img>
        </div>

        <div className='tips-box'>
          <div className='tips'>方法三</div>
          <div className='adorn'></div>
        </div>

        <div className='content'>
          <div>自购省!在抖音商城看到喜欢的商品，可以点击分享-复制链接，来到平台进行转链，转链后也可以获得推广佣金。</div>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/douke_share3_1.png'></img>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/douke_share3_2.png'></img>
          <img className='img-step' alt='service' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/douke_share3_3.png'></img>
        </div>


      </div>
    );
  }
}

export default DoukeShare;
