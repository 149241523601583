import React, { Component } from 'react'
import './index.css'
import axios from 'axios'

class JoinGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // serviceQrCode: 'https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/join_group.png',
      serviceQrCode: null,
    }
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: '/miniapp-api/v1/banner/img-list?show_type=5',
    }).then((res) => {
      this.setState({
        serviceQrCode: res.data.data[0].img_link
      })
    }).catch((error) => {
      console.log(error)
    })

  }

  render() {

    const { serviceQrCode } = this.state;

    return (
      <div className='join-group-body'>
        <div className='join-group-title-box'>
          <div>每天更新新通告，</div>
          <div>快来加入通告群！</div>
        </div>
        <img className='bg' alt='join grroup bg' src='https://wby-download-storage.oss-cn-beijing.aliyuncs.com/caelus/miniapp/assets/join_group_bg.png'></img>

        <div className='tips-box'>
          <div className='tips'>小贴士</div>
          <div className='adorn'></div>
        </div>

        <div className='content'>
          欢迎您来到易通告平台，平台每天更新大量全新的通告，平台PR会在所有通告中精选优质通告，每天自动发送到平台达人群中，避免您错过优质通告，欢迎加入易通告达人企业微信群，随时关注群内消息～
          <div className='qrcode-box'>
            <img className='qrcode' alt='service' src={`${serviceQrCode}?t=` + new Date()}></img>
          </div>
        </div>
      </div>
    );
  }
}

export default JoinGroup;
